import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeKo from '@angular/common/locales/ko';
import { LOCALE_ID, NgModule } from '@angular/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';
import { AuthInterceptor, PW_STORAGE_PREFIX } from 'pw-lib';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogModule } from './components/dialog/dialog.module';
import { NgFormlyModule } from './components/formly/formly.module';
import { LayoutModule } from './components/layout/layout.module';
import { maskConfig } from './core/mask-config';
import { HomeComponent } from './pages/home/home.component';
import { AuthService } from './shared/auth.service';
import { PipesModule } from './shared/pipes/pipes.module';
import { PwMissingTranslationHandler } from './shared/services/pw-missing-translation-handler.service';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeKo);
const locale = 'ko-KR';

const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const matDialogDefaultOptions: MatDialogConfig = {
  autoFocus: false,
  minWidth: '240px',
  panelClass: 'dialog-panel',
  hasBackdrop: true,
  backdropClass: 'dialog-backdrop',
};

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    // EntityRoutingModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    DialogModule,
    PipesModule,
    SharedModule,
    // NgxEchartsModule.forRoot({
    //   echarts: () => import('echarts'),
    // }),
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forRoot({
      defaultLanguage: 'ko',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      // 선택한 언어의 번역 값이 적절하게 없을 때.
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: PwMissingTranslationHandler,
      },
    }),
    LayoutModule,
    NgFormlyModule,
    MatNativeDateModule,
    // UserSelectorModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useFactory: (authService: AuthService) => {
        return new AuthInterceptor(
          authService,
          `${environment.apiServerUrl}/api`
        );
      },
      deps: [AuthService],
    },
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    { provide: LOCALE_ID, useValue: locale },
    { provide: MAT_DATE_LOCALE, useValue: locale },
    { provide: PW_STORAGE_PREFIX, useValue: 'joyfood' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: matDialogDefaultOptions },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
