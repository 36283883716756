<div mat-dialog-content>
  <pw-summernote
    class="field-cn"
    id="cnInput"
    [formControl]="control"
    [uploadUrl]="imgUploadUrl"
  ></pw-summernote>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClickCancel()">
    {{ 'BTN.cancel' | translate }}
  </button>
  <button mat-button (click)="onClickSave()">
    {{ 'BTN.save' | translate }}
  </button>
</div>
